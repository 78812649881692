import "../../../assets/styles/Inbox.css";
import "../../../assets/styles/TicketsNexus.css";

import React, { useEffect, useRef, useState } from "react";
import { FiCalendar, FiPlus, FiSearch, FiShare2, FiX } from "react-icons/fi";
import DOMPurify from "dompurify";
import { useToasts } from "react-toast-notifications";
import { useRouteMatch, useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Pills from "../../../components/Pills";
import { useAuth } from "../../../hooks/useAuth";
import { useComitente } from "../use-comitente";

import ModalTicket from "./ModalTicket";
import ModalTicketEdit from "./ModalTicketEdit";
import useTickets from "./use-tickets";

const TicketsNexus = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { user } = useAuth();
  const { comitenteId } = useComitente();
  const cardRef = useRef(undefined);
  const [size, setSize] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalTicket, setModalTicket] = useState({
    data: { dueDate: new Date().toISOString() },
    show: false,
    action: "ADD",
  });

  const [modalTicketEdit, setModalTicketEdit] = useState({ data: {}, show: false, action: "ADD" });
  const [active, setActive] = useState(parseFloat(match.params?.id) || undefined);
  const { tickets, users, createTicket, editTicket } = useTickets(comitenteId);

  useEffect(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight - (cardRef.current?.offsetTop || 0),
    });
    // eslint-disable-next-line
  }, [cardRef.current?.offsetTop, window.innerHeight, window.innerWidth]);

  const handleCreateTicket = (data) => {
    createTicket(data);
    setModalTicket((prev) => ({ ...prev, show: false }));
  };

  const handleEditTicket = (data) => {
    editTicket({ data, modalTicketEdit });
    setModalTicketEdit((prev) => ({ ...prev, show: false }));
  };
  const activeTicket = tickets?.find((t) => t.id === active);
  return (
    <div style={{ height: "100vh" }}>
      <div className="inbox">
        {(!active || size.width >= 1000) && (
          <div className="inbox-sidebar">
            <div className="inbox-sidebar-header">
              <div className="inbox-search">
                <FiSearch className="search-icon" />
                <Input
                  name="text"
                  placeholder="Buscar Ticket"
                  onChange={(e) => setFilter(e.target.value)}
                  onFocus={(e) => e.target.select()}
                  value={filter}
                  autoComplete="off"
                />
                <button className="cross-icon" type="button" onClick={() => setFilter("")}>
                  <FiX />
                </button>
              </div>
            </div>
            <div className="inbox-sidebar-body">
              <Button
                color="green"
                style={{
                  width: "80%",
                  margin: "0 10% 10px 10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() =>
                  setModalTicket((prev) => ({
                    ...prev,
                    show: true,
                    data: { ...prev.data, comitenteId, user },
                    action: "ADD",
                  }))
                }
              >
                <FiPlus style={{ strokeWidth: 3, marginRight: 5 }} />
                Agregar
              </Button>
              {tickets &&
                tickets
                  .filter((t) => t.title.toLowerCase().includes(filter.toLowerCase()))
                  .map((ticket, i) => {
                    return (
                      <TicketItem
                        key={i}
                        ticket={ticket}
                        users={users}
                        history={history}
                        comitenteId={comitenteId}
                        active={active}
                        setActive={setActive}
                      />
                    );
                  })}
              {tickets?.length < 1 && (
                <h5
                  style={{
                    marginTop: 50,
                    textAlign: "center",
                    color: "hsl(222, 10%, 60%)",
                    fontWeight: 600,
                  }}
                >
                  No hay tickets
                </h5>
              )}
            </div>
          </div>
        )}
        {(active || size.width >= 1000) && activeTicket && (
          <Chat
            setModalTicketEdit={setModalTicketEdit}
            activeTicket={activeTicket}
            setActive={setActive}
            size={size}
          />
        )}
        {!activeTicket && (
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "hsl(220, 10%, 60%)",
              transform: "translateY(-10px)",
            }}
          >
            No hay Ticket seleccionado
          </h4>
        )}
      </div>

      <ModalTicket
        modal={modalTicket}
        users={users}
        setModal={setModalTicket}
        handleSubmit={handleCreateTicket}
      />
      <ModalTicketEdit
        modal={modalTicketEdit}
        users={users}
        setModal={setModalTicketEdit}
        handleSubmit={handleEditTicket}
      />
    </div>
  );
};

const TicketItem = ({ ticket, active, setActive, users, comitenteId }) => {
  const history = useHistory();

  const venc = new Date(ticket.dueDate);

  const dest =
    ["", ...JSON.parse(ticket.arrayDestination || "[]")].reduce(
      (acc, curr) =>
        acc + " " + (users?.find((us) => us.sub === parseInt(curr))?.username || "#" + curr)
    ) || "Todos";

  const diff = (venc.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24;
  return (
    <div
      key={ticket.id}
      className={`ticket-item${active === ticket.id ? " active" : ""}`}
      onClick={() => {
        history.push({
          pathname: `/${history.location.pathname.split("/")[1]}/${comitenteId}/tickets_nexus/${
            active !== ticket.id ? ticket.id : ""
          }`,
        });
        setActive((prev) => (prev !== ticket.id ? ticket.id : undefined));
      }}
    >
      <div>
        <div className="details">
          <Pills
            color={
              ticket.status !== 1 ? "transparent" : diff < -2 ? "red" : diff < 0 ? "yellow" : "grey"
            }
          >
            <FiCalendar /> {venc.format()}
          </Pills>

          {ticket.status !== 1 ? (
            <Pills color={ticket.ESTADO === 0 ? "green" : "red"}>
              {ticket.status === 0 ? "Hecho" : "Anulado"}
            </Pills>
          ) : (
            <h6>Para {dest}</h6>
          )}
        </div>
        <h4>{ticket.title}</h4>
      </div>
    </div>
  );
};

const Chat = ({ activeTicket, setActive, size, setModalTicketEdit }) => {
  const chatBody = useRef(undefined);
  const { user } = useAuth();
  const { addToast } = useToasts();
  const { comitenteId } = useComitente();

  const handleShareUrl = async () => {
    try {
      await navigator.clipboard.writeText(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_NEXUS_URL}/comitente/${comitenteId}/tickets/${activeTicket.id}`
      );

      addToast("Url copiada al portapapeles!", { appearance: "success" });
    } catch (err) {
      addToast("Error al copiar la url al portapapeles!", { appearance: "error" });
      console.log(err);
    }
  };

  useEffect(() => {
    if (chatBody.current) chatBody.current.scrollBy({ behaviour: "smooth", top: 10000000 });
  }, [activeTicket]);
  return (
    <div className="chat">
      <div className="chat-header">
        <h4>
          {size.width > 1000 && (
            <span style={{ cursor: "pointer" }} onClick={handleShareUrl}>
              #{activeTicket.id}
            </span>
          )}
          <span style={{ alignSelf: "end" }}>
            <FiShare2 style={{ cursor: "pointer", marginRight: 6 }} onClick={handleShareUrl} />
          </span>
          {activeTicket.title}
        </h4>
        <Pills color="transparent" style={{ width: 160, textAlign: "right" }}>
          <FiCalendar /> {new Date(activeTicket.dueDate).format()}
        </Pills>
      </div>
      <div className="chat-body" ref={chatBody}>
        {activeTicket.messages.map((bubble, i) => (
          <div
            key={i}
            className={`message ${bubble.user === user.username ? "mine" : "other"}${
              new Date(bubble.dueDate).getFullYear() > 2000 ? " venc" : ""
            }${bubble.status === 1 ? "" : bubble.status === 0 ? " finalizado" : " anulado"}
                  `}
          >
            {bubble.user !== user.username && <h4 className="user">{bubble.user}</h4>}
            <span className="date">{new Date(bubble.date).format()}</span>
            <div className="bubble">
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(bubble.comment.replace(/(\r\n|\n|\r)/gm, "<br>")),
                }}
              ></span>
              {new Date(bubble.dueDate).getFullYear() > 2000 && (
                <div className="new-venc">Vto. {new Date(bubble.dueDate).format()}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="chat-footer">
        {size.width >= 1000 || (
          <Button color="secondary" onClick={() => setActive(undefined)}>
            Volver
          </Button>
        )}
        <Button
          color="green"
          onClick={() => setModalTicketEdit({ show: true, action: "ADD", data: activeTicket })}
        >
          Escribir
        </Button>
      </div>
    </div>
  );
};

export default TicketsNexus;
