import React from "react";

const Select = ({
  label,
  name,
  errorMsg,
  handleChange,
  handleBlur,
  getAlert,
  getValue,
  children,
}) => {
  const error = getAlert(name);

  return (
    <div className={`form-group${error ? " error" : ""}`} style={{ marginBottom: 24 }}>
      <label>{label}</label>
      <select
        style={error ? { border: "1px solid red" } : {}}
        name={name}
        value={getValue(name)}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {children}
      </select>
      {error && <span className="error">{errorMsg}</span>}
    </div>
  );
};

export default Select;
