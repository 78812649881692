import React, { memo, useCallback, useMemo, useState } from "react";
import { FiEdit, FiEye, FiMail, FiSearch, FiTrash2 } from "react-icons/fi";

import Button from "../../components/Button";
import Card from "../../components/Card";
import Input from "../../components/Input";
import { Table } from "../../components/Table";
import { confirmAlert } from "../../components/Confirm";
import { useAuth } from "../../hooks/useAuth";

import ModalEdit from "./ModalEdit";
import ModalInfo from "./ModalInfo";
import { useAgenda } from "./use-agenda";

const ellipsisStyles = { overflow: "hidden", textOverflow: "ellipsis" };

const formatMail = (value) => (
  <div
    style={{
      cursor: "pointer",
      color: "rgb(80, 86, 98)",
      textDecorationColor: "rgb(115, 123, 140)",
      textDecoration: "underline",
    }}
    onDoubleClick={() => window.open("mailto:" + value, "emailWindow")}
  >
    {value?.length > 0 && <FiMail style={{ marginRight: 2, marginBottom: -2 }} />}
    {value}
  </div>
);

const columns = [
  {
    style: { maxWidth: 80, ...ellipsisStyles },
    accessor: "name",
    label: "Nombre",
  },
  {
    style: { maxWidth: 100, ...ellipsisStyles },
    accessor: "organisation",
    label: "Organización",
  },
  {
    style: { maxWidth: 100, ...ellipsisStyles },
    accessor: "phone",
    label: "Telefono",
  },
  {
    style: { maxWidth: 130, ...ellipsisStyles, userSelect: "none" },
    accessor: "email",
    label: "Mail",
    format: formatMail,
  },
  {
    style: { maxWidth: 80, ...ellipsisStyles },
    accessor: "comment",
    label: "Comentario",
  },
];

const Agenda = () => {
  const { isLoading, agenda, editContact, addContact, deleteContact } = useAgenda();
  const [search, setSearch] = useState("");
  const [modalInfo, setModalInfo] = useState({ action: "", show: false, data: {} });
  const [modalEdit, setModalEdit] = useState({ action: "", show: false, data: {} });

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleEditForm = useCallback(
    (formData) => {
      if (modalEdit.action === "EDIT") editContact(modalEdit.data.id, formData);
      else if (modalEdit.action === "ADD") addContact(formData);

      setModalEdit((prev) => ({ ...prev, show: false }));
    },
    [addContact, editContact, modalEdit.action, modalEdit.data.id]
  );

  const handleDelete = useCallback(
    (row) => {
      confirmAlert({
        title: "Borrar Contacto",
        message: `Eliminar Contacto ${row.name} (Org. ${row.organisation})`,
        buttons: [
          {
            label: "Borrar",
            onClick: () => deleteContact(row.id),
          },
          { label: "Cancelar", color: "transparent" },
        ],
      });
    },
    [deleteContact]
  );

  const actionsProps = useMemo(
    () => ({ setModalInfo, setModalEdit, handleDelete }),
    [handleDelete]
  );

  const tableData = useMemo(
    () =>
      agenda.filter(
        (row) =>
          row.name?.toLowerCase().includes(search.toLowerCase()) ||
          row.organisation?.toLowerCase().includes(search.toLowerCase()) ||
          row.phone?.toLowerCase().includes(search.toLowerCase()) ||
          row.email?.toLowerCase().includes(search.toLowerCase()) ||
          row.comment?.toLowerCase().includes(search.toLowerCase())
      ),
    [agenda, search]
  );

  return (
    <>
      <div className="header-extended comitente" style={{ height: 95 }}>
        <div className="container header" style={{ paddingBottom: 0 }}>
          <h3 style={{ margin: 0, paddingLeft: 10, fontWeight: 600 }}>Agenda</h3>
        </div>
      </div>
      <div className="container">
        <Card
          style={{
            padding: "10px 10px",
            marginTop: -30,
            overflow: "visible",
            display: "flex",
            gap: 10,
          }}
        >
          <Input
            style={{ width: "min(300px, calc(100vw - 178px))" }}
            placeholder="Busqueda"
            onChange={handleChange}
          />
          <Button color="secondary" style={{ paddingTop: 9, paddingBottom: 7 }}>
            <FiSearch />
          </Button>
          <Button onClick={() => setModalEdit({ action: "ADD", data: {}, show: true })}>
            Agregar Contacto
          </Button>
        </Card>
        <Card
          style={{
            marginTop: 20,
            minHeight: "min(calc(100vh - 230px), 600px)",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <Table
            columns={columns}
            rows={tableData}
            Actions={Actions}
            actionProps={actionsProps}
            actionsAtStart
          />
          {!isLoading && tableData.length <= 0 && (
            <h4 style={{ color: "#808080", textAlign: "center", marginTop: 40 }}>
              No se encuentran resultados
            </h4>
          )}
          {isLoading && (
            <h4 style={{ color: "#808080", textAlign: "center", marginTop: 40 }}>Cargando...</h4>
          )}
        </Card>
      </div>
      <ModalEdit modal={modalEdit} setModal={setModalEdit} handleSubmit={handleEditForm} />
      <ModalInfo modal={modalInfo} setModal={setModalInfo} />
    </>
  );
};

const Actions = memo(function Actions({ row, setModalInfo, setModalEdit, handleDelete }) {
  const { user } = useAuth();
  return (
    <>
      <Button
        icon
        size="xs"
        color="green"
        onClick={() => setModalInfo({ show: true, action: "SEE", data: row })}
      >
        <FiEye />
      </Button>
      <Button
        icon
        size="xs"
        onClick={() => setModalEdit({ show: true, action: "EDIT", data: row })}
      >
        <FiEdit />
      </Button>
      {user.tipo === "ADMINISTRADOR" && (
        <Button size="xs" icon color="red" onClick={() => handleDelete(row)}>
          <FiTrash2 />
        </Button>
      )}
    </>
  );
});

export default Agenda;
