import React, { useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Principal from "../containers/Home";
import Comitente from "../containers/Comitente";
import Extras from "../containers/Extras";
import Agenda from "../containers/Agenda";
import Proveedor from "../containers/Supplier";
import Login from "../containers/Login";
import Navbar from "../components/Navbar";
import ModalAssets from "../containers/Home/ModalAsset";
import { useAuth } from "../hooks/useAuth";
import { PositionProvider } from "../containers/Comitente/Position/use-position";
import { CheckingProvider } from "../containers/Comitente/Checking/use-checking";
import { TradesProvider } from "../containers/Comitente/Trades/use-trades";
import { TicketsNexus } from "../containers/Comitente/Tickets";
import Tickets from "../components/Tickets";
import "../assets/styles/TicketsNexus.css";

const App = () => {
  const { isAuth } = useAuth();
  const [search, setSearch] = useState({ type: "mezcla", text: "" });
  const [modalAssets, setModalAssets] = useState({ show: false, action: "SHOW", data: {} });

  const location = useLocation();

  return (
    <>
      {isAuth &&
        window.location.pathname.split("/")[1] !== "login" &&
        !window.location.pathname.includes("tickets_nexus") && (
          <Navbar search={search} setSearch={setSearch} setModalAssets={setModalAssets} />
        )}
      <Switch>
        <Route path="/login" render={() => <Login />} />
        {!isAuth && <Redirect to={{ pathname: "/login", state: location }} />}
        <Route path={`/comitente/:comitente/tickets_nexus/:id?`} component={TicketsNexus} />
        <Route path={`/proveedor/:comitente/tickets_nexus/:id?`} component={TicketsNexus} />
        <Route
          path={`/tickets_nexus`}
          component={() => <Tickets isNexus className="tickets-nexus" show={true} />}
        />
        {/* <Route path="/comitente/:comitente" component={ComitenteInContext} />
        <Route path="/proveedor/:comitente" component={Proveedor} />
        <Route path="/extras" render={() => <Extras search={search} setSearch={setSearch} />} />
        <Route path="/agenda" render={() => <Agenda search={search} setSearch={setSearch} />} />
        <Route path="/" render={() => <Principal search={search} setSearch={setSearch} />} /> */}
      </Switch>
      {/* <ModalAssets modal={modalAssets} setModal={setModalAssets} /> */}
    </>
  );
};

const ComitenteInContext = () => (
  <PositionProvider>
    <CheckingProvider>
      <TradesProvider>
        <Comitente />
      </TradesProvider>
    </CheckingProvider>
  </PositionProvider>
);

export default App;
