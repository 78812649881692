import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Error from "../steps/Error";
import PerfilInversor from "../steps/PerfilInversor";
import Success from "../steps/Success";

const PerfilInversorFormulario = () => {
  const match = useRouteMatch();
  const [verification, setVerification] = useState(false);
  const [enviado, setEnviado] = useState(false);

  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.onresize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API}/forms/perfilInversor/verifyHash/${match.params.hash}`, {
      // eslint-disable-next-line no-undef
      headers: { Authorization: `Bearer ${process.env.REACT_APP_INTERNAL_COMMUNICATION_TOKEN}` },
    })
      .then((data) => data.json())
      .then((verification) => {
        setVerification(verification);
      })
      .catch(() => {
        setVerification({
          error: true,
          completed: false,
          message: "Ocurrió un error en la conexión con el servidor!",
        });
      });
  }, [match.params.hash]);

  const handleSubmit = (data) => {
    const result = Object.values(data).reduce(
      (prev, curr) => (prev = parseInt(prev) + parseInt(curr))
    );
    let perfil = "";

    if (result >= 45) perfil = "Agresivo";
    else if (result >= 22) perfil = "Moderado";
    else perfil = "Conservador";

    setEnviado(true);
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API}/forms/perfilInversor/${match.params.hash}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // eslint-disable-next-line no-undef
        Authorization: `Bearer ${process.env.REACT_APP_INTERNAL_COMMUNICATION_TOKEN}`,
      },
      body: JSON.stringify({ ...data, resultado: result, perfil }),
    })
      .then((data) => data.json())
      .then((data) => {
        setVerification(data);
      })
      .catch(() => {
        setVerification({
          error: true,
          completed: false,
          message: "Ocurrió un error en la conexión con el servidor!",
        });
      });
  };

  return (
    <>
      <div
        style={
          size.width >= 1000
            ? {
                position: "fixed",
                top: 0,
                right: 0,
                height: "100vh",
                width: "max(calc(100% - 900px), calc(40% + 50px))",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage:
                  "url(https://aldazabalmediamanager.prod.ingecloud.com/MediaFiles/Files/2017/10_30/0/0/17/4539.jpg)",
                zIndex: 1,
              }
            : {
                position: "relative",
                width: "100%",
                height: 200,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage:
                  "url(https://aldazabalmediamanager.prod.ingecloud.com/MediaFiles/Files/2017/10_30/0/0/17/4539.jpg)",
                zIndex: 1,
              }
        }
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "hsl(220, 90%, 50%, 30%)",
            position: "absolute",
          }}
        ></div>
        {size.width >= 1000 && (
          <svg style={{ height: "100%", width: 100, position: "absolute", left: -1 }}>
            <polygon
              points={`0,0 0,${size.height} 100,${size.height}`}
              style={{ fill: "#e6e7ec" }}
            />
          </svg>
        )}
      </div>

      <div
        style={{
          minHeight: "100vh",
          minWidth: size.width <= 440 ? "100%" : 400,
          maxWidth: 900,
          width: size.width >= 1000 ? "calc(60% - 50px)" : "100%",
          margin: size.width >= 1000 ? "0 auto 0 0" : "0 auto",
          zIndex: 2,
          display: "flex",
          padding: size.width <= 500 ? "50px 25px" : "50px 75px",
        }}
      >
        <div style={{ maxWidth: 500, flexGrow: 1, margin: "auto" }}>
          {verification && (
            <>
              <div
                style={{
                  margin: "0 0 0 auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <img
                  style={{ width: 210 }}
                  src="https://exe.aldazabal.com.ar/clientes/img/logo/logoNuevo.svg"
                  alt="Logo de Aldazabal &amp; Cia."
                />
              </div>
              {!verification.error && !verification.completed && (
                <PerfilInversor
                  buttonText={enviado ? "Enviando..." : "Enviar"}
                  disabled={enviado}
                  onSubmit={handleSubmit}
                />
              )}
              {verification.error && verification.completed && (
                <Success
                  title="Su perfil ya fue completado"
                  message={verification.message}
                  paragraph={`Cualquier duda con su perfil de inversor \n consulte a info@aldazabal.com.ar`}
                />
              )}
              {!verification.error && verification.completed && (
                <Success
                  title="Envio Exitoso"
                  message={verification.message}
                  paragraph={`Cualquier duda con su perfil de inversor \n consulte a info@aldazabal.com.ar`}
                />
              )}
              {verification.error && !verification.completed && (
                <Error
                  title="Ocurrió un error"
                  message={verification.message}
                  paragraph={`Cualquier duda con su perfil de inversor \n consulte a info@aldazabal.com.ar`}
                />
              )}{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PerfilInversorFormulario;
