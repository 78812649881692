import axios from "axios";
import { useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";

const getTickets = async (url, addToast, isTicket) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    isTicket
      ? addToast("Error al obtener los tickets!", { appearance: "error" })
      : addToast("Error al obtener los usuarios!", { appearance: "error" });
    console.log(e);
  }
};

export const useTickets = () => {
  const { addToast } = useToasts();

  const { data: ticketsData } = useSWR(["/tickets/complete"], (...rest) =>
    getTickets(...rest, addToast, true)
  );
  const { data: usersData } = useSWR(["/user/all"], (...rest) => getTickets(...rest, addToast));

  const tickets = useMemo(() => ticketsData || [], [ticketsData]);
  const users = useMemo(() => usersData || [], [usersData]);

  return { tickets, users };
};
