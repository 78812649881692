import React from "react";
import { FiCheckCircle } from "react-icons/fi";

const Error = ({ title, message, style, paragraph }) => {
  return (
    <div
      style={{
        color: "hsl(5deg 60% 60%)",
        position: "relative",
        bottom: 0,
        height: "70vh",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        paddingTop: "80px",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <FiCheckCircle style={{ fontSize: 100 }} />
      <h1 style={{ margin: "40px 0 0 0" }}>{title}</h1>
      <h3 style={{ margin: 0, color: "hsl(120deg 5% 40%)" }}>{message}</h3>
      <p style={{ color: "hsl(120deg 5% 50%)" }}>{paragraph}</p>
    </div>
  );
};

export default Error;
