import axios from "axios";
import { useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";

import { useAuth } from "../../hooks/useAuth";

const getNotifications = async (url, body, addToast) => {
  try {
    const { data } = await axios.post(url, body);
    return data;
  } catch (e) {
    addToast("Error al obtener las notificaciones!", { appearance: "error" });
    console.log(e);
  }
};

export const useNotifications = () => {
  const { addToast } = useToasts();
  const { user } = useAuth();

  const { data, mutate } = useSWR(["/notificaciones", { id: user.sub }], (...rest) =>
    getNotifications(...rest, addToast)
  );
  const notifications = useMemo(() => data || [], [data]);

  return { notifications, mutate };
};
