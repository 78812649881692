import React, { useEffect, useRef } from "react";

import Button from "../../components/Button";
import { useFormCreator } from "../../hooks/useFormCreator";
import Select from "../FormsInputs";
import PerfilInversorQuestions from "../../assets/static/forms/PerfilInversorQuestions.js";

const initialValues = {};

PerfilInversorQuestions.forEach((question) => {
  initialValues[question.name] = {
    value: "",
    validator: question.validator,
  };
});

const PerfilInversor = ({ onSubmit, disabled, buttonText }) => {
  const refSendButton = useRef();
  const { canSubmit, getValues, inputInfo, trySubmit } = useFormCreator({
    initialValues,
  });

  const result = canSubmit()
    ? Object.values(getValues()).reduce((prev, curr) => (prev = parseInt(prev) + parseInt(curr)))
    : -1;

  useEffect(() => {
    if (canSubmit()) {
      setTimeout(() => {
        const position = refSendButton?.current?.getBoundingClientRect();
        if (position) {
          window.scrollTo({
            top: position.y + position.height + 25 - window.innerHeight,
            behavior: "smooth",
          });
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmit()]);

  return (
    <div>
      <h1>Perfil Inversor</h1>
      {PerfilInversorQuestions.map((question) => (
        <Select
          key={question.name}
          label={question.label}
          name={question.name}
          {...inputInfo}
          errorMsg={question.errorMsg || "Selecciona un Opción!"}
        >
          <option value="">------</option>
          {question.options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ))}

      {result !== -1 && (
        <div
          style={{
            backgroundColor: "hsl(212deg 65% 95%)",
            padding: "5px 25px",
            borderRadius: 6,
            border: "2px solid #1675e0",
            margin: "10px 0 20px 0",
          }}
        >
          {result >= 45 && (
            <>
              <h4 style={{ margin: "12px 0" }}>Inversor Agresivo</h4>
              <p>
                Usted valora la rentabilidad ante todo. En vista de su experiencia con la inversión
                en renta variable, usted entiende y acepta que la inversión en especies de riesgo
                alto puede reportar interesantes rentimientos pero también pérdidas considerables.
              </p>
            </>
          )}
          {result >= 22 && result < 45 && (
            <>
              <h4 style={{ margin: "12px 0" }}>Inversor Moderado</h4>
              <p>
                Usted Valora la rentabilidad, pero siempre teniendo presente el riesgo que supone
                invertir en mercado de renta variable.
              </p>
            </>
          )}
          {result < 22 && (
            <>
              <h4 style={{ margin: "12px 0" }}>Inversor Conservador</h4>
              <p>
                Usted valora la seguridad de su inversion por encima de la rentabilidad. Por
                supuesto, busca la máxima rentabilidad posible, pero siempre que no suponga asumir
                riesgos importantes sobre su inversión ni una merma en la liquidez.
              </p>
            </>
          )}
        </div>
      )}

      <Button reference={refSendButton} onClick={() => trySubmit(onSubmit)} disabled={disabled}>
        {buttonText}
      </Button>
    </div>
  );
};

export default PerfilInversor;
