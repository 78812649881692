const questions = [
  {
    name: "rangoEdad",
    label: "Su edad se ecuentra dentro del rango de",
    options: [
      { label: "Menos de 25 años", value: "8" },
      { label: "De 25 a 35 años", value: "6" },
      { label: "De 36 a 55 años", value: "3" },
      { label: "Mas de 55 años", value: "0" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "fraseIdentifica",
    label: "¿Con cuál de las siguientes frases se identifica?",
    options: [
      { label: "No conozco ni realicé nunca inversiones", value: "0" },
      {
        label: "No realicé nunca inversiones, pero conozco sobre algunas posibilidades de invertir",
        value: "2",
      },
      { label: "He realizado inversiones y conozco algunas alternativas de inversión", value: "4" },
      {
        label: "Tengo experiencia y conocimiento de los riesgos y rentabilidad de invertir",
        value: "6",
      },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "porcentajeAhorros",
    label:
      "¿Qué porcentaje de sus ahorros esta dispuesto a destinar a las inversiones en el Mercado de capitales?",
    options: [
      { label: "Menos del 25%", value: "0" },
      { label: "Entre el 25% y el 40%", value: "2" },
      { label: "Entre el 41% y el 65%", value: "4" },
      { label: "Más del 65%", value: "6" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "tiempoInversion",
    label: "¿Cuánto tiempo conservaría esta inversión?",
    options: [
      { label: "Menos de 180 días", value: "0" },
      { label: "Entre 180 días y 1 año", value: "3" },
      { label: "Entre 1 y 2 años", value: "6" },
      { label: "Más de 2 años", value: "8" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "objetivoInversion",
    label: "A la hora de invertir, ¿Cuál es su objetivo?",
    options: [
      {
        label: "Preservar el dinero invertido con una rentabilidad similar a un plazo fijo",
        value: "0",
      },
      {
        label:
          "Obtener una rentabilidad mayor a un plazo fijo, tolerando las alzas y bajas del mercado",
        value: "3",
      },
      { label: "Obtener una rentabilidad superior a la media del mercado", value: "6" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "preocupacion",
    label: "Cuando invierto mi dinero, estoy:",
    options: [
      { label: "Mayormente preocupado por las pérdidas de valor de mi cartera", value: "0" },
      { label: "Preocupado por las perdidas y ganancias de mi cartera", value: "4" },
      { label: "Mayormente preocupado por las ganancias de mi cartera", value: "8" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "actitudRiesgo",
    label:
      "Existiendo la posibilidad de mejorar la rentabilidad de tus inversiones, tu actitud frente al riesgo sería:",
    options: [
      {
        label:
          "No aceptaría inversiones con rentabilidades que, aunque positivas, resulten inciertas",
        value: "0",
      },
      {
        label:
          "Toleraría fluctuaciones en la rentabilidad de su capital, siempre que no resulten negativas",
        value: "2",
      },
      { label: "De tiempo en tiempo, toleraría rentabilidades negativas", value: "5" },
      { label: "Toleraría perfectamente las fluctuaciones propias del mercado", value: "8" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "reaccionCaida",
    label: "Suponga que en los últimos meses el mercado de capitales perdió un 25%, ¿Qué hariá?",
    options: [
      { label: "Vendería todas mis acciones", value: "0" },
      { label: "Vendería parte de mis acciones", value: "2" },
      { label: "Nada", value: "5" },
      { label: "Compraría más acciones", value: "8" },
    ],
    validator: (value) => value !== "",
    value: "",
  },
  {
    name: "actitudInversiones",
    label:
      "Indique cuál de las siguientes afirmaciones identifica mejor su actitud hacia las inversiones:",
    options: [
      { label: "No acepto inversiones que puedan ocasionar pérdidas de capital", value: "0" },
      {
        label:
          "Mi objetivo es obtener un rendimiento moderado y estable, sin sufrir variaciones significativas en el valor de mi inversión",
        value: "4",
      },
      {
        label:
          "Aceptaría una disminución en el valor de mis inversiones, con el objetivo de mejorar la rentabilidad esperada en el mediano / largo plazo",
        value: "8",
      },
      {
        label:
          "Prefiero invertir en activos riesgosos de alta rentabilidad potencial, sabiendo que puedo enfrentar pérdidas significativas en el valor de mi inversión",
        value: "10",
      },
    ],
    validator: (value) => value !== "",
    value: "",
  },
];

export default questions;
