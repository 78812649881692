import React, { memo, useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { stringify } from "superjson";

import Button from "../../../components/Button";
import Card from "../../../components/Card";
import { Table } from "../../../components/Table";
import { confirmAlert } from "../../../components/Confirm";
import Pills from "../../../components/Pills";
import UploadModal from "../../../components/UploadModal";

import Modal from "./Modal";
import { useDayTrades } from "./use-day-trades";

const columns = [
  { className: "text-center", accessor: "i", label: "Enviar" },
  { accessor: "comitenteId", className: "text-center", label: "Comitente" },
  {
    accessor: "comitente",
    style: { maxWidth: 400, overflow: "hidden", textOverflow: "ellipsis" },
    label: "Descripción",
  },
  {
    accessor: "email",
    style: { maxWidth: 350, overflow: "hidden", textOverflow: "ellipsis" },
    label: "Mail",
  },
  {
    accessor: "file",
    label: "PDF",
    format: (file, row) =>
      file ? (
        Boolean(row.email) ? (
          <Pills color="green">Generado</Pills>
        ) : (
          <Pills color="yellow">Sin Email</Pills>
        )
      ) : (
        <Pills color="red">Faltante</Pills>
      ),
  },
  { accessor: "quantity", className: "text-center", label: "# de Boletos" },
];

async function uploadToS3(formData, addToast) {
  const files = formData.getAll("files");
  if (!files) {
    return null;
  }

  try {
    await Promise.all(
      files.map(async (file) => {
        const input = {
          name: `${new Date().toISOString()}/${file.name}`,
          type: file.type,
        };
        const { data } = await axios.get(
          `${
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_CLIENTES_NEXT_URL
          }/api/trpc/dayTrades.getUploadFileUrl?input=${encodeURIComponent(stringify(input))}`
        );

        const { uploadUrl } = data.result.data.json;
        await fetch(uploadUrl, { method: "PUT", body: file });
      })
    );
    addToast("Archivos subidos correctamente!", { appearance: "success" });
  } catch (e) {
    console.log(e);
    addToast(`Error al subir los archivos`, { appearance: "error" });
  }
}

const DayTradesMailing = () => {
  const [modal, setModal] = useState({ show: false, data: {} });
  const {
    comitentes,
    validateSend,
    toggleAllSendMail,
    sendMails,
    toggleSendMail,
    trades,
    isLoading,
    mutateFiles,
  } = useDayTrades();

  const { addToast } = useToasts();

  const openDetails = useCallback(
    (row) => {
      setModal({ show: true, data: trades.filter((t) => t.comitenteId === row.comitenteId) });
    },
    [trades]
  );
  const [uploadModal, setUploadModal] = useState({ show: false });

  const actionProps = useMemo(() => ({ openDetails }), [openDetails]);
  const tableData = useMemo(() => {
    return comitentes.map((comitente) => {
      return {
        ...comitente,
        i: (
          <input
            type="checkbox"
            checked={comitente.send}
            onChange={() => toggleSendMail(comitente.comitenteId)}
            disabled={!Boolean(comitente.email)}
          />
        ),
      };
    });
  }, [comitentes, toggleSendMail]);

  const handleSendMails = useCallback(() => {
    confirmAlert({
      title: "Enviar Mails",
      message: `Enviar mail de las operaciones del dia a los comitentes seleccionados?`,
      buttons: [
        {
          label: "Enviar",
          onClick: () => sendMails(),
        },
        { label: "Cancelar", onClick: () => {}, color: "transparent" },
      ],
    });
  }, [sendMails]);

  if (isLoading) {
    return (
      <h4 style={{ color: "#808080", textAlign: "center", marginTop: 40 }}>Cargando boletos...</h4>
    );
  } else {
    return (
      <>
        <UploadModal
          uploadModal={uploadModal}
          setUploadModal={setUploadModal}
          title="Subir Boletos"
          allowMultiple={true}
          handleUpload={async (e) => {
            await uploadToS3(e, addToast);
            mutateFiles();
          }}
        />
        {comitentes.length === 0 ? (
          <div style={{ textAlign: "right", marginTop: 20 }}>
            <h4 style={{ color: "#808080", textAlign: "center", marginTop: 10 }}>
              No hay Boletos generados en el día
            </h4>
          </div>
        ) : (
          <>
            <div style={{ margin: "auto", maxWidth: 1200, paddingTop: 20 }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h4
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        display: "flex",
                        gap: 16,
                        alignItems: "center",
                      }}
                    >
                      Mail de Boletos del {new Date().format()}
                      {validateSend() && <Button onClick={handleSendMails}>Enviar</Button>}
                      <Button onClick={toggleAllSendMail}>
                        {comitentes.some((a) => a.send)
                          ? "Deseleccionar todos"
                          : "Seleccionar todos"}
                      </Button>
                    </h4>
                  </div>
                  <Button onClick={() => setUploadModal({ show: true })}>Subir Boletos</Button>
                </div>
                <Card style={{ marginTop: 10 }}>
                  <Table
                    totalTheme="light"
                    columns={columns}
                    Actions={Actions}
                    actionProps={actionProps}
                    rows={tableData}
                  />
                </Card>
              </div>
            </div>
            <Modal modal={modal} setModal={setModal} />
          </>
        )}
      </>
    );
  }
};

const Actions = memo(function Actions({ row, openDetails }) {
  return (
    <>
      <Button size="xs" onClick={() => openDetails(row)}>
        Ver
      </Button>
    </>
  );
});

export default DayTradesMailing;
