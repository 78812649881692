import React, { memo } from "react";
import "../assets/styles/Button.css";

function Button(props) {
  const {
    size,
    reference,
    color,
    active,
    className,
    style,
    onClick,
    icon,
    children,
    disabled = false,
  } = props;

  let buttonStyle = "btn";
  if (className) buttonStyle += `${className}`;
  if (size) buttonStyle += ` btn-${size}`;
  if (active) buttonStyle += ` active`;
  if (icon) buttonStyle += ` btn-icon`;
  if (color) buttonStyle += ` btn-${color}`;
  else buttonStyle += ` btn-primary`;

  return (
    <button
      ref={reference}
      disabled={disabled}
      style={style}
      aria-label={icon ? "btn-icon" : children}
      className={buttonStyle}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default memo(Button);
