import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import "../../assets/styles/Login.css";

import Card from "../../components/Card";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useAuth } from "../../hooks/useAuth";

const Login = () => {
  const { register, handleSubmit, errors } = useForm();
  const { login } = useAuth();
  const location = useLocation();
  const [error, setError] = useState(false);

  const onSubmit = handleSubmit((data) => handleLogin(data));

  const handleLogin = (data) =>
    login({ loginData: data, redirectTo: location.state?.pathname }).catch((err) => {
      setError(true);
      console.log(err);
    });

  return (
    <main className="login">
      <Card>
        <img
          alt="Logo Aldazabal & Cia."
          src="https://media.aldazabal.com.ar/clientes/img/logo/logoNuevo.svg"
        />
        <form onSubmit={onSubmit}>
          <h2>Iniciar Sesión</h2>
          <Input
            label="Usuario"
            name="username"
            register={register({ required: true })}
            errors={errors}
            errMsg="El usuario es requerido!"
          />
          <Input
            type="password"
            label="Contraseña"
            name="password"
            register={register({ required: true })}
            errors={errors}
            errMsg="La contraseña es requerida!"
          />
          <Button type="submit">Entrar</Button>
          <div className="errors-div">{error && "Usuario y/o contraseña incorrectos!"}</div>
        </form>
      </Card>
    </main>
  );
};

export default Login;
