import { useState } from "react";

const useFormCreator = ({ initialValues = {} }) => {
  const [data, setData] = useState(() => {
    Object.keys(initialValues).forEach((key) => {
      const data = initialValues[key];
      data.isValid = data.validator ? data.validator(data.value) : true;
    });
    return initialValues;
  });
  const [alerts, setAlerts] = useState([]);

  const handleChange = (e) => {
    const { name, value: newValue } = e.target;
    const validator = data[name]?.validator;
    setData({
      ...data,
      [name]: { value: newValue, validator, isValid: validator ? validator(newValue) : true },
    });
    if (validator && validator(newValue))
      setAlerts((oldAlerts) => oldAlerts.filter((alert) => alert !== name));
  };

  const trySubmit = (onSubmit) => {
    const invalidFields = Object.keys(data).filter((key) => !data[key].isValid);
    if (invalidFields.length) {
      setAlerts(invalidFields);
      return;
    }
    onSubmit(getValues(data));
  };

  const canSubmit = () => Object.values(data).every((value) => value.isValid);

  const getValue = (name) => data[name]?.value || "";
  const getIsValid = (name) => data[name]?.isValid || false;
  const getAlert = (name) => alerts.includes(name);

  const handleBlur = (e) =>
    !getIsValid(e.target.name) && setAlerts((oldAlerts) => [...oldAlerts, e.target.name]);

  const getValues = () => {
    const values = {};
    Object.keys(data).forEach((key) => {
      values[key] = data[key].value;
    });
    return values;
  };

  return {
    data,
    trySubmit,
    handleChange,
    handleBlur,
    canSubmit,
    getValue,
    getIsValid,
    getAlert,
    getValues,
    inputInfo: {
      handleChange,
      handleBlur,
      getAlert,
      getValue,
    },
  };
};

const useSteps = ({ totalSteps = 1 }) => {
  const [step, setStep] = useState(1);

  const getStepsInfo = () => [step, totalSteps];
  const changeStep = (newStep) => newStep >= 1 && newStep <= totalSteps && setStep(newStep);

  const canMoveBackwards = () => step > 1;
  const canMoveForwards = () => step < totalSteps;

  const moveBackwards = () => setStep((lastStep) => (canMoveBackwards() ? lastStep - 1 : lastStep));
  const moveForwards = () => setStep((lastStep) => (canMoveForwards() ? lastStep + 1 : lastStep));

  return {
    step,
    getStepsInfo,
    canMoveBackwards,
    canMoveForwards,
    moveBackwards,
    moveForwards,
    changeStep,
  };
};

export { useFormCreator, useSteps };
