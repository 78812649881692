import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import questions from "../../assets/static/forms/PerfilInversorQuestions";

const PerfilInversorPdf = () => {
  const match = useRouteMatch();
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { hash } = match.params;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API}/forms/perfilInversor/${match.params.hash}`, {
      headers: {
        "Content-Type": "application/json",
        // eslint-disable-next-line no-undef
        Authorization: `Bearer ${process.env.REACT_APP_INTERNAL_COMMUNICATION_TOKEN}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          setError("Error al obtener el formulario");
        } else setData(res);
      })
      .catch(() => setError("Error en la comunicacion con el servidor"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const answers = data?.DATOS_CARGADOS ? JSON.parse(data?.DATOS_CARGADOS) : undefined;

  let result = 0;

  questions.forEach((question) => {
    const value = parseInt(answers?.[question.name]);
    if (value) result += value;
  });

  return (
    <>
      <style>
        {`html, body {
        background: #fff;
      }`}
      </style>
      {error && <div>{error}</div>}
      {data && (
        <div className="container" style={{ background: "#fff" }}>
          <div
            style={{
              margin: "0px 0px 0px auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img
              src="https://media.aldazabal.com.ar/clientes/img/logo/logoNuevo.svg"
              alt="Logo de Aldazabal &amp; Cia."
              style={{ width: 190 }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ marginBottom: 0 }}>
              Fecha:{" "}
              <span style={{ position: "relative", bottom: -2 }}>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    position: "absolute",
                    display: "block",
                    right: "50%",
                    bottom: 5,
                    transform: "translateX(50%)",
                  }}
                >
                  {new Date(data.FECHA_COMPLETADO).format()}
                </span>
                ………………………………
              </span>
            </h5>
            <h5 style={{ marginBottom: 0 }}>
              Comitente:{" "}
              <span style={{ position: "relative", bottom: -2 }}>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    position: "absolute",
                    display: "block",
                    right: "50%",
                    // top: 0,
                    bottom: 5,
                    transform: "translateX(50%)",
                  }}
                >
                  {data.COMITENTE}
                </span>
                ………………………………
              </span>
            </h5>
          </div>
          <h1 style={{ marginTop: 20, fontSize: 24, marginBottom: 10 }}>Perfil Inversor</h1>
          <div style={{ fontSize: 11 }}>
            {questions.map((question, i) => (
              <div key={i}>
                <h4 style={{ marginTop: 10, marginBottom: 5 }}>
                  {i + 1}. {question.label}
                </h4>
                {question.options.map((option, j) => (
                  <div
                    key={j}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "21px 1fr",
                      alignItems: "center",
                      color: option.value === answers[question.name] ? "hsl(220, 80%, 60%)" : "",
                      fontWeight: option.value === answers[question.name] ? "bold" : "",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        background: "#e6e7ec",
                        width: 14,
                        height: 14,
                        borderRadius: 4,
                        marginRight: 5,
                      }}
                    >
                      {option.value === answers[question.name] && (
                        <span
                          style={{
                            display: "inline-block",
                            background: "hsl(220, 80%, 60%)",
                            width: 12,
                            height: 12,
                            margin: 1,
                            borderRadius: 3,
                          }}
                        ></span>
                      )}
                    </span>
                    {option.label}
                  </div>
                ))}
              </div>
            ))}
            {result && result >= 45 && (
              <>
                <h2 style={{ margin: "20px 0 5px 0" }}>Resultado: Inversor Agresivo</h2>
                <p style={{ margin: 0 }}>
                  Usted valora la rentabilidad ante todo. En vista de su experiencia con la
                  inversión en renta variable, usted entiende y acepta que la inversión en especies
                  de riesgo alto puede reportar interesantes rentimientos pero también pérdidas
                  considerables.
                </p>
              </>
            )}
            {result && result >= 22 && result < 45 && (
              <>
                <h4 style={{ margin: "12px 0" }}>Inversor Moderado</h4>
                <p>
                  Usted Valora la rentabilidad, pero siempre teniendo presente el riesgo que supone
                  invertir en mercado de renta variable.
                </p>
              </>
            )}
            {result && result < 22 && (
              <>
                <h4 style={{ margin: "10px 0 5px 0" }}>Inversor Conservador</h4>
                <p>
                  Usted valora la seguridad de su inversion por encima de la rentabilidad. Por
                  supuesto, busca la máxima rentabilidad posible, pero siempre que no suponga asumir
                  riesgos importantes sobre su inversión ni una merma en la liquidez.
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PerfilInversorPdf;
