import React from "react";
import ReactDOM from "react-dom";
import { SWRConfig } from "swr";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./assets/styles/index.css";

import Clientes from "./routes/Clientes";
import axiosConfig from "./helpers/axios";
import numbersConfig from "./helpers/numbers";
import datesConfig from "./helpers/dates";
import Formularios from "./routes/Formularios";

axiosConfig();
numbersConfig();
datesConfig();

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://6cdf733d60524695a7e3452bab9d25bb@o1224188.ingest.sentry.io/6368935",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const options = {
  fetcher: async (resource, init) => {
    const { data } = await axios.get(resource, init);
    return data;
  },
};

ReactDOM.render(
  <SWRConfig value={options}>
    <ToastProvider placement="bottom-right" autoDismiss={true}>
      <BrowserRouter>
        <Switch>
          {Formularios.map((formulario, index) => (
            <Route
              key={index}
              path={formulario.path}
              render={(props) => <formulario.component {...props} />}
            />
          ))}
          <Route path="/">
            <Clientes />
          </Route>
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  </SWRConfig>,
  document.getElementById("root")
);
