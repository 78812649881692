import React, { memo } from "react";
import { FiAlertTriangle, FiEye, FiMessageSquare, FiTrash, FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Button from "../../components/Button";

import "../../assets/styles/Notifications.css";
import { useNotifications } from "./use-notifications";

const NotificationPanel = memo(function NotificationPanel({ isOpen, setIsOpen }) {
  const history = useHistory();
  const { notifications, mutate } = useNotifications();

  const fetchData = () => mutate();

  return (
    <>
      {notifications.some((a) => a.NO_LEIDO === 1) && (
        <div className="notification-alert" onClick={() => setIsOpen((prev) => !prev)}>
          {notifications.filter((a) => a.NO_LEIDO === 1).length}
        </div>
      )}
      {isOpen && (
        <div className="notification-panel">
          <div>
            <h4>
              Notificaciones{" "}
              <Button
                style={{ float: "right" }}
                onClick={() => setIsOpen(false)}
                icon
                color="transparent"
              >
                <FiX />
              </Button>
            </h4>
            {notifications.length === 0 && (
              <div className="notificaciones-anuncio">No hay Notificaciones Pendientes!</div>
            )}
            {notifications.map((noti) => {
              if (noti.TIPO === "TICKET")
                return (
                  <NotificationTicket
                    key={noti.ID}
                    data={noti}
                    history={history}
                    setIsOpen={setIsOpen}
                    fetchData={fetchData}
                  />
                );
              else if (noti.TIPO === "ERROR SCRAP")
                return (
                  <NotificationError
                    data={noti}
                    key={noti.ID}
                    setIsOpen={setIsOpen}
                    fetchData={fetchData}
                  />
                );
              return <></>;
            })}
          </div>
        </div>
      )}
    </>
  );
});

const Notification = memo(function Notification({
  time,
  title,
  noLeido,
  strong,
  icon,
  type,
  handleDelete,
  handleRead,
  handleClick,
}) {
  return (
    <div
      className={`notification-container${noLeido === 1 ? " no-leido" : ""}`}
      onClick={(e) => e.target.tagName !== "BUTTON" && handleClick()}
    >
      <div className={`notification-icon ${type || "message"}`}>{icon}</div>
      <div className="notification-body">
        <div className="notification-info">
          {strong && <strong style={{ marginRight: 5 }}>{strong}</strong>}
          {title}
        </div>
        <div className="notification-time">{time}</div>
        <div className="notification-actions">
          {noLeido === 1 ? (
            <Button icon color="transparent" onClick={handleRead}>
              <FiEye style={{ marginTop: 0 }} />
              Marcar como leido
            </Button>
          ) : (
            <Button icon color="transparent" onClick={handleDelete}>
              <FiTrash />
              Eliminar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

const handleDelete = ({ fetchData, data }) =>
  axios
    .get("/notificaciones/borrar/" + data.ID)
    .then(() => fetchData())
    .catch((err) => console.log(err));

const handleRead = ({ fetchData, data }) =>
  axios
    .get("/notificaciones/leer/" + data.ID)
    .then(() => fetchData())
    .catch((err) => console.log(err));

const NotificationError = memo(function NotificationError({ data, fetchData, setIsOpen }) {
  const handleClick = () => {
    setIsOpen(false);
    axios
      .get("/notificaciones/leer/" + data.ID)
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  return (
    <Notification
      noLeido={data.NO_LEIDO}
      bgcolor={data.NO_LEIDO === 1 && "hsl(0 70% 92%)"}
      color={data.NO_LEIDO === 1 && "hsl(0 30% 55% / 1)"}
      time={getTime(data.FECHA)}
      type="error"
      icon={<FiAlertTriangle style={{ marginTop: -1.3 }} />}
      handleClick={handleClick}
      strong="Error"
      handleDelete={() => handleDelete({ fetchData, data })}
      handleRead={() => handleRead({ fetchData, data })}
      title={JSON.parse(data.DATOS).titulo}
    />
  );
});

const NotificationTicket = ({ data, history, setIsOpen, fetchData }) => {
  const handleClick = () => {
    setIsOpen(false);
    history.push({
      pathname: `/comitente/${JSON.parse(data.DATOS).comitenteId}/tickets/${
        JSON.parse(data.DATOS).ticketId
      }`,
    });
    axios
      .get("/notificaciones/leer/" + data.ID)
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  return (
    <Notification
      noLeido={data.NO_LEIDO}
      icon={<FiMessageSquare />}
      bgcolor={data.NO_LEIDO === 1 && "hsl(220, 60%, 95%)"}
      color={data.NO_LEIDO === 1 && "hsl(220, 20%, 40%)"}
      time={getTime(data.FECHA)}
      handleClick={handleClick}
      handleDelete={() => handleDelete({ fetchData, data })}
      handleRead={() => handleRead({ fetchData, data })}
      strong={JSON.parse(data.DATOS).strong}
      title={JSON.parse(data.DATOS).titulo}
    />
  );
};

const getTime = (date) => {
  const milli = new Date().getTime() - new Date(date).getTime();
  if (milli / 60000 < 1)
    return Math.trunc(milli / 1000) + (Math.trunc(milli / 1000) > 1 ? " segundos" : " segundo");
  else if (milli / 60000 / 60 < 1)
    return Math.trunc(milli / 60000) + (Math.trunc(milli / 60000) > 1 ? " minutos" : " minuto");
  else if (milli / 60000 / 60 / 24 < 1)
    return (
      Math.trunc(milli / 60000 / 60) + (Math.trunc(milli / 60000 / 60) > 1 ? " horas" : " hora")
    );
  else
    return (
      Math.trunc(milli / 60000 / 60 / 24) +
      (Math.trunc(milli / 60000 / 60 / 24) > 1 ? " días" : " día")
    );
};

export default NotificationPanel;
