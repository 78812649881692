import axios from "axios";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";

import { useAuth } from "../../../hooks/useAuth";
import { useComitente } from "../use-comitente";

const getTickets = async (url, comitenteId, history, addToast) => {
  try {
    const { data } = await axios.get(
      url + comitenteId + "/" + history.location.pathname.split("/")[1] || ""
    );
    return data;
  } catch (err) {
    console.log(err);
    return addToast("Error al cargar Tickets", { appearance: "error" });
  }
};
const getUsers = async (url, addToast) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (err) {
    console.log(err);
    return addToast("Error al cargar Tickets", { appearance: "error" });
  }
};

const useTickets = () => {
  const { addToast } = useToasts();
  const { comitenteId } = useComitente();
  const history = useHistory();
  const { user } = useAuth();

  const { data: ticketsData, mutate: mutateTickets } = useSWR(
    ["/tickets/comitente/", comitenteId, history],
    (...args) => getTickets(...args, addToast)
  );
  const tickets = useMemo(() => ticketsData || [], [ticketsData]);

  const { data: usersData } = useSWR([`/user/all`], (...args) => getUsers(...args, addToast));
  const users = useMemo(() => usersData || [], [usersData]);

  const createTicket = useCallback(
    (data) => {
      axios
        .post("/tickets/", {
          user: user.username,
          title: data.title,
          dueDate: data.dueDate,
          comitenteId: parseInt(comitenteId),
          comitenteType: history.location.pathname.split("/")[1],
          arrayDestination: JSON.stringify(data.users.map((a) => a.value)),
        })
        .then(() => mutateTickets());
    },
    [comitenteId, history, mutateTickets, user]
  );

  const editTicket = useCallback(
    ({ data, modalTicketEdit }) => {
      axios
        .put("/tickets/" + modalTicketEdit.data.id, {
          comitenteId: modalTicketEdit.data.comitenteId,
          user: user.username,
          comment: data.comment,
          dueDate: modalTicketEdit.data.dueDate !== data.dueDate ? data.dueDate : null,
          arrayDestination: JSON.stringify(data.users.map((a) => a.value)),
          status: data.status,
          changeTitle: data.changeTitle,
        })
        .then(() => mutateTickets());
    },
    [mutateTickets, user]
  );
  return { tickets, users, createTicket, editTicket };
};

export default useTickets;
