import React from "react";
import { useForm } from "react-hook-form";

import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import bancosJson from "../../../assets/static/bancos.json";

function validateCBU(cbu) {
  const validateLengthCBU = (cbu) => {
    if (cbu.length !== 22) return false;
    return true;
  };

  const validateBankCode = (code) => {
    if (code.length !== 8) return false;
    const bank = code.substr(0, 3);
    const sucursal = code.substr(3, 4);
    const verifierDigit = code[7];

    const sum =
      bank[0] * 7 +
      bank[1] * 1 +
      bank[2] * 3 +
      sucursal[0] * 9 +
      sucursal[1] * 7 +
      sucursal[2] * 1 +
      sucursal[3] * 3;
    const diff = 10 - (sum % 10);

    return diff === (parseInt(verifierDigit) !== 0 ? parseInt(verifierDigit) : 10);
  };

  const validateAccount = (account) => {
    if (account.length !== 14) return false;
    const verifierDigit = account[13];
    const sum =
      account[0] * 3 +
      account[1] * 9 +
      account[2] * 7 +
      account[3] * 1 +
      account[4] * 3 +
      account[5] * 9 +
      account[6] * 7 +
      account[7] * 1 +
      account[8] * 3 +
      account[9] * 9 +
      account[10] * 7 +
      account[11] * 1 +
      account[12] * 3;
    const diff = 10 - (sum % 10);

    return diff === (parseInt(verifierDigit) !== 0 ? parseInt(verifierDigit) : 10);
  };
  return (
    validateLengthCBU(cbu) &&
    validateBankCode(cbu.substr(0, 8)) &&
    validateAccount(cbu.substr(8, 14))
  );
}

function validateCUIT(cuit) {
  cuit = cuit.toString();
  if (cuit.length !== 11) {
    return false;
  }

  let acumulated = 0;
  let digits = cuit.split("");
  let digit = digits.pop();

  for (let i = 0; i < digits.length; i++) {
    acumulated += digits[9 - i] * (2 + (i % 6));
  }

  let verif = 11 - (acumulated % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }

  return parseInt(digit) === verif;
}

const ModalAccount = ({ modal, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  const handleCBU = (e) => {
    const data = e.target.value;
    const banco = bancosJson[data.substr(0, 3)];
    if (banco) setValue("bank", banco);
  };

  return (
    <Modal
      show={modal.show}
      setModal={setModal}
      title={modal.action === "ADD" ? "Agregar Cuenta" : `Editar Cuenta`}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <Input
          autoFocus
          register={register({
            required: true,
            validate: { valid: (value) => validateCUIT(value) },
          })}
          errors={errors}
          errMsg="El CUIT es Invalido!"
          name="CUIT"
          value={modal.data.CUIT}
          label="CUIT"
        />
        <Input
          register={register({ required: true })}
          errors={errors}
          errMsg="Escriba una Moneda!"
          name="currency"
          value={modal.data.currency}
          label="Moneda"
        />
        <Input
          register={register({
            required: true,
            validate: { valid: (value) => validateCBU(value) },
          })}
          onChange={handleCBU}
          errors={errors}
          errMsg="El CBU es invalido!"
          name="CBU"
          value={modal.data.CBU}
          label="CBU"
        />
        <Input
          register={register({ required: true })}
          errors={errors}
          errMsg="El Banco es requerido!"
          name="bank"
          value={modal.data.bank}
          label="Banco"
        />
        <Input
          register={register({ required: true })}
          errors={errors}
          errMsg="El Tipo Cuenta es requerido!"
          name="type"
          value={modal.data.type}
          label="Tipo Cuenta"
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </Modal>
  );
};

export default ModalAccount;
