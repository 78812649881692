import axios from "axios";
import { useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";

const getAssets = async (url, addToast) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    addToast("Error al obtener los especies!", { appearance: "error" });
    console.log(e);
  }
};

export const useNavbar = () => {
  const { addToast } = useToasts();
  const { data } = useSWR(["/search/especies"], (...rest) => getAssets(...rest, addToast));

  const assets = useMemo(() => data || [], [data]);

  return { assets };
};
