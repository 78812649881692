import React from "react";

import Ticket from "../Ticket";

import { useTickets } from "./use-tickets";

const Tickets = ({ history, show, user, className, isNexus }) => {
  const { tickets, users } = useTickets();

  return (
    <ul className={`tickets ${className}`} style={{ display: show ? "grid" : "none" }}>
      {tickets.map((ticket, i) => (
        <Ticket
          isNexus={isNexus}
          key={i}
          history={history}
          users={users}
          user={user}
          ticket={ticket}
        />
      ))}
    </ul>
  );
};

export default Tickets;
