import React, { useRef } from "react";

import Card from "../../../components/Card";
import Copy from "../../../components/Copy";
import { Table } from "../../../components/Table";
import { useComitente } from "../use-comitente";

import { usePosition } from "./use-position";

const columns = [
  { accessor: "type", className: "text-center", label: "Tipo" },
  { accessor: "description", className: "text-center", label: "Concepto" },
  {
    accessor: "assetCode",
    className: "text-right",
    format: (a) => (a === 0 ? "-" : a),
    label: "Código",
  },
  { accessor: "abbreviation", className: "text-center", label: "Abreviatura" },
  { accessor: "quantity", isNumber: true, label: "Cantidad" },
  { accessor: "price", isNumber: true, label: "Cotizacón" },
  {
    accessor: "date",
    format: (val) => (val ? (new Date(val).getFullYear() > 1900 ? new Date(val) : new Date()) : ""),
    isDate: true,

    label: "Fecha Cot.",
  },
  { accessor: "amount", isNumber: true, label: "Monto" },
  { accessor: "amountArs", isNumber: true, label: "Monto Pesos", total: true },
];

const Position = () => {
  const { comitenteId, comitente } = useComitente();

  const { position, isLoading, positionDatePretty } = usePosition();

  const tablaPosicion = useRef(undefined);

  if (isLoading)
    return (
      <h4 style={{ color: "#808080", textAlign: "center", marginTop: 40 }}>Cargando Posición</h4>
    );

  if (position?.length === 0)
    return (
      <h4 style={{ color: "#808080", textAlign: "center", marginTop: 40 }}>
        No tiene una Posición del día {positionDatePretty}
      </h4>
    );

  return (
    <>
      <Card style={{ marginTop: 15 }}>
        <Table reference={tablaPosicion} className="position" columns={columns} rows={position} />
      </Card>
      <Copy
        style={{ marginTop: 10 }}
        reference={tablaPosicion}
        title={`${comitenteId} ${comitente.name}`}
      />
    </>
  );
};

export default Position;
