import React, { useCallback, useMemo, useState } from "react";
import { FiSearch, FiBell, FiMenu } from "react-icons/fi";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";

import "../../assets/styles/Navbar.css";

import { useAuth } from "../../hooks/useAuth";
import NotificationPanel from "../Notifications";

import { useNavbar } from "./use-navbar";

const Navbar = ({ setSearch, setModalAssets }) => {
  const { user, signout } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [comitenteInput, setComitenteInput] = useState("");
  const section = location.pathname.split("/")[1] || "principal";
  const [open, setOpen] = useState(section === "principal");
  const [notifications, setNotifications] = useState(false);

  const { assets } = useNavbar();

  const onSubmitComitente = useCallback(
    (e) => {
      e.preventDefault();
      e.target.blur();
      setSearch((prev) => ({ ...prev, enter: true, text: comitenteInput }));
      setOpen(false);
      if (section !== "principal") history.push({ pathname: "/" });
    },
    [comitenteInput, history, section, setSearch]
  );

  const searchComitente = useMemo(
    () => (
      <form onSubmit={onSubmitComitente}>
        <div className="nav-search">
          <input
            name="text"
            type="text"
            placeholder="Buscar Comitente"
            onFocus={(e) => e.target.select()}
            value={comitenteInput}
            onChange={(e) => setComitenteInput(e.target.value.toUpperCase())}
            autoComplete="off"
          />
          <button type="button" onClick={onSubmitComitente}>
            <FiSearch className="search-icon" />
          </button>
        </div>
      </form>
    ),
    [comitenteInput, onSubmitComitente]
  );

  const searchAsset = useMemo(
    () => (
      <div className="nav-search-select">
        <Select
          onMenuClose={() =>
            document
              .querySelectorAll(
                ".nav-search-select > div > div:first-of-type > div:first-of-type > div[class*='singleValue']"
              )
              .forEach(
                (a) =>
                  (a.innerHTML = "<span style='color: hsl(216, 12%, 65%)'>Buscar Especie</span>")
              )
          }
          placeholder="Buscar Especie"
          onChange={(e) => e && setModalAssets({ show: true, data: { asset: e.value } })}
          options={assets.map((a) => ({ label: a.value, value: a.value }))}
        />
        <button type="button" onClick={() => setModalAssets((prev) => ({ ...prev, show: true }))}>
          <FiSearch className="search-icon" />
        </button>
      </div>
    ),
    [assets, setModalAssets]
  );

  return (
    <nav className={open ? "open" : ""}>
      <div className="container">
        <div>
          <div className="nav-content">
            <div className="nav-left">
              <h1 style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: "/" })}>
                Clientes
              </h1>
              <div className="nav-pages">
                <NavLink to="/extras/">Extras</NavLink>
                <NavLink to="/agenda/">Agenda</NavLink>
              </div>
              {searchComitente}
            </div>
            <div className="nav-right">
              {searchAsset}
              <div className="actions">
                <div style={{ position: "relative" }}>
                  <button className="alerts" style={{ cursor: "pointer" }}>
                    <FiBell onClick={() => setNotifications((prev) => !prev)} />
                  </button>
                  <NotificationPanel
                    isOpen={notifications}
                    setIsOpen={setNotifications}
                    user={user}
                  />
                </div>
                <button onDoubleClick={() => signout()} className="user">
                  {user.abv || ""}
                </button>

                <button className="menu-button" onClick={() => setOpen((prev) => !prev)}>
                  <FiMenu />
                </button>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <div className="nav-pages">
              <NavLink to="/extras/">Extras</NavLink>
              <NavLink to="/agenda/">Agenda</NavLink>
            </div>
            {searchComitente}
            {searchAsset}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
