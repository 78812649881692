import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import decodeJWT from "jwt-decode";
import useSWR from "swr";

const getUser = () =>
  axios.get("/user/whoami").then((res) => {
    if (!res) return;
    window.localStorage.setItem("token", res.data.accessToken);
    return res.data;
  });

const accessToken = window.localStorage.getItem("token");
const user = accessToken ? decodeJWT(accessToken) : null;
const initialState = { accessToken, user };

export const useAuth = () => {
  const history = useHistory();

  const { data, error, mutate } = useSWR("/user/whoami", getUser, {
    shouldRetryOnError: false,
    fallbackData: initialState,
    dedupingInterval: 60 * 60 * 1000,
  });

  const login = useCallback(
    async ({ loginData, redirectTo = "/" }) => {
      const { data } = await axios.post("/user/login", loginData);
      window.localStorage.setItem("token", data.accessToken);
      mutate(data, false);
      console.log(
        "Login succeed! Redirecting to:",
        redirectTo.includes("login") || !redirectTo || redirectTo.length < 1 ? "/" : redirectTo,
        {
          redirectTo,
          includesLogin: redirectTo.includes("login"),
          isNil: !redirectTo,
          empty: redirectTo?.length < 1,
        }
      );
      history.push(
        redirectTo.includes("login") || !redirectTo || redirectTo.length < 1 ? "/" : redirectTo
      );
    },
    [history, mutate]
  );

  const signout = useCallback(() => {
    window.localStorage.removeItem("token");
    history.push("/login");
    mutate();
  }, [history, mutate]);

  const isAuth = useMemo(
    () => Boolean(data?.accessToken) && decodeJWT(data?.accessToken).exp * 1000 > Date.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.accessToken]
  );

  const user = useMemo(() => data?.user || {}, [data.user]);

  return {
    isLoading: !data && !error,
    isAuth,
    error,
    mutate,
    user,
    login,
    signout,
  };
};
