import axios from "axios";

const axiosConfig = () => {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem("token")}`;
    return config;
  });

  // eslint-disable-next-line no-undef
  axios.defaults.baseURL = process.env.REACT_APP_API;

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      console.log(error.response);
      if (error.response.status === 403 || error.response.status === 401) {
        window.localStorage.removeItem("token");
        if (window.location.pathname !== "/login") {
          window.location = "/login";
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }

      return Promise.reject(error);
    }
  );
};

export default axiosConfig;
