import axios from "axios";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useSWR from "swr";

const getComitente = async (url, comitenteId, addToast) => {
  try {
    const { data } = await axios.get(url + comitenteId);
    return data;
  } catch (err) {
    addToast("Error cargando Comitente!", { appearance: "error" });
    throw err;
  }
};

export function useComitente() {
  const { addToast } = useToasts();
  const { params, url } = useRouteMatch();
  const comitenteId = parseInt(params.comitente) || 0;
  const path = url.split("/")[1];

  const { data, error, mutate } = useSWR(
    [`/${path}/`, comitenteId],
    (...props) => getComitente(...props, addToast),
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );

  const comitente = useMemo(() => data || {}, [data]);
  return {
    comitente,
    comitenteId,
    isLoading: !data && !error,
    refresh: mutate,
  };
}
