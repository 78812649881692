import React from "react";
import { useHistory } from "react-router-dom";
import { FiCalendar, FiUsers } from "react-icons/fi";

import { useAuth } from "../hooks/useAuth";

import Pills from "./Pills";

const Ticket = ({ ticket, users = [], isNexus }) => {
  const history = useHistory();
  const { user } = useAuth();
  const {
    type,
    comitenteId = "",
    title = "",
    dueDate = new Date(),
    userDestination = "",
    arrayDestination = "[]",
    date = new Date(),
  } = ticket;

  const venc = new Date(dueDate);
  const creac = new Date(date);

  const dest =
    ["", ...JSON.parse(arrayDestination || "[]")].reduce(
      (acc, curr) =>
        acc + " " + (users.find((us) => us.sub === parseInt(curr))?.username || "#" + curr)
    ) || "Todos";

  const mine =
    JSON.parse(arrayDestination || "[]").findIndex((u) => u === user?.sub) > -1 ||
    JSON.parse(arrayDestination || "[]").length < 1;

  const diff = (venc.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24;
  const handleClick = () =>
    isNexus
      ? // eslint-disable-next-line no-undef
        (window.top.location.href = `${process.env.REACT_APP_NEXUS_URL}/${
          type === 99 ? "proveedor" : "comitente"
        }/${comitenteId}/tickets/${ticket.id}`)
      : history.push({
          pathname: `/${type === 99 ? "proveedor" : "comitente"}/${comitenteId}/tickets/${
            ticket.id
          }`,
        });

  return (
    <li className={`ticket${mine ? " mine" : ""} cursor-pointer`} onClick={handleClick}>
      <div className="info">
        <h3>{comitenteId} </h3>
        <h4>{title}</h4>
      </div>
      <div className="to">
        <Pills color={diff < -2 ? "red" : diff < 0 ? "yellow" : "grey"}>
          <FiCalendar /> {venc.format()}
        </Pills>
        <FiUsers />{" "}
        {JSON.parse(arrayDestination || "[]").length > 0
          ? dest
          : (userDestination || "[]").replace("@", " ").trimStart()}
      </div>
      <div className="from">
        <h5>
          <span>Creado el</span> {creac.format()}
        </h5>
        {/* <h5>
          <span>Por</span> {USUARIO_ORIGEN}
        </h5> */}
      </div>
    </li>
  );
};

export default Ticket;
